import "../CSS/App.css"
import "../CSS/scroll.css"
import "../CSS/responsive.css"
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXTwitter, faInstagram, faGithub, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'; // "@fortawesome/free-regular-svg-icons"
import { faEnvelope, faComputer, faBriefcase, faGraduationCap, faUser, faHouse, faBars, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
//import MiFoto from "../IMG/MiFoto.jpg";
import FotoMia from "../IMG/yop.jpg";
import logoReact from "../IMG/logo-react.png"
import logoNode from "../IMG/logo-node.png"
import logoHtml from "../IMG/logo-html.png"
import logoCss from "../IMG/css-logo.png"
import logoJs from "../IMG/logo-js.png"
import logoMongo from "../IMG/logo-mongoDb.png"
import certificadoPython from "../IMG/certificado_coder_python.png"
import certificadoNode from "../IMG/certificado_udemy_node.jpg"
import certificadoMongo from "../IMG/certificado_udemy_mongo.jpg"
import certificadoTypescript from "../IMG/certificado_udemy_typescript.jpg"
import certificadoMasterCss from "../IMG/certificado_udemy_mastercss.jpg"
import certificadoTesting from "../IMG/Diploma_Testing_CAC.jpg"
import logoStelea from "../IMG/Logo-stelea.jpeg"
import logoCambioHoy from "../IMG/Cambio-hoy-logo.jpeg"
import logoEasyQr from "../IMG/easy-qr-logo-web.jpg"
import logoCults from "../IMG/logo-cults.jpg"


function Inicio() {
    // Hooks para icono y animación del menu responsive
    const [menuHamburguesa, setMenuHamburguesa] = useState(true);
    const [asideActive, setAsideActive] = useState("layout__aside")
    const [costadoAside, setCostadoAside] = useState("layout__aside-fondo")

    const desplegarMenu = () => {
        setMenuHamburguesa(false);
        setAsideActive("layout__aside--active")
        setCostadoAside("layout__aside-fondo--active")
    }
    const ocultarMenu = () => {
        setMenuHamburguesa(true)
        setAsideActive("layout__aside")
        setCostadoAside("layout__aside-fondo")
    } 

    // Animación de carga para mapa de contacto
    const [loader, setLoader] = useState(true);

    // Hooks para renderizar información.
    const [inicioKey, setInicioKey] = useState(true);
    const [sobreMiKey, setSobreMiKey] = useState(false);
    const [estudiosKey, setEstudiosKey] = useState(false);
    const [portafolioKey, setPortafolioKey] = useState(false);
    const [skillsKey, setSkillsKey] = useState(false);
    const [contactoKey, setContactoKey] = useState(false);

    const mostrarInicio = () => {
        setInicioKey(true);
        setSobreMiKey(false);
        setEstudiosKey(false);
        setPortafolioKey(false);
        setSkillsKey(false);
        setContactoKey(false);
        setLoader(true);
        ocultarMenu();
    }
    const mostrarSobreMi = () => {
        setInicioKey(false);
        setSobreMiKey(true);
        setEstudiosKey(false);
        setPortafolioKey(false);
        setSkillsKey(false);
        setContactoKey(false);
        setLoader(true);
        ocultarMenu();
    }
    const mostrarEstudios = () => {
        setInicioKey(false);
        setSobreMiKey(false);
        setEstudiosKey(true);
        setPortafolioKey(false);
        setSkillsKey(false);
        setContactoKey(false);
        setLoader(true);
        ocultarMenu();
    }
    const mostrarPortafolio= () => {
        setInicioKey(false);
        setSobreMiKey(false);
        setEstudiosKey(false);
        setPortafolioKey(true);
        setSkillsKey(false);
        setContactoKey(false);
        setLoader(true);
        ocultarMenu();
    }
    const mostrarSkills = () => {
        setInicioKey(false);
        setSobreMiKey(false);
        setEstudiosKey(false);
        setPortafolioKey(false);
        setSkillsKey(true);
        setContactoKey(false);
        setLoader(true);
        ocultarMenu();
    }
    const mostrarContacto = () => {
        setInicioKey(false);
        setSobreMiKey(false);
        setEstudiosKey(false);
        setPortafolioKey(false);
        setSkillsKey(false);
        setContactoKey(true);
        ocultarMenu();

        setTimeout(() => {
            setLoader(false);
          }, 3500);
    }

    // Hooks para ver certificados con zoom.
    const [verTsc, setVerTsc] = useState(false);
    const [verNode, setVerNode] = useState(false);
    const [verMongo, setVerMongo] = useState(false);
    const [verPython, setVerPython] = useState(false);
    const [verCss, setVerCss] = useState(false);
    const [verTesting, setVerTesting] = useState(false);

    const verCertificadoTsc = () => {
        setVerTsc(true);
    }
    const verCertificadoNode = () => {
        setVerNode(true);
    }
    const verCertificadoMongo = () => {
        setVerMongo(true);
    }
    const verCertificadoPython = () => {
        setVerPython(true);
    }
    const verCertificadoMasterCss = () => {
        setVerCss(true);
    }
    const verCertificadoTesting = () => {
        setVerTesting(true);
    }
    const ocultarCertificados = () => {
        setVerTsc(false);
        setVerNode(false);
        setVerMongo(false);
        setVerPython(false);
        setVerCss(false);
        setVerTesting(false);
    }
    
    
    // Hooks para navbar de portafolio.
    const [verTodo, setVerTodo] = useState(true);
    const [verWeb, setVerWeb] = useState(false);
    const [verDesign, setVerDesign] = useState(false);
    const [verImpresion, setVerImpresion] = useState(false);

    const activarVerTodo = () => {
        setVerTodo(true);
        setVerWeb(false);
        setVerDesign(false);
        setVerImpresion(false);
    }
    const activarVerWeb = () => {
        setVerTodo(false);
        setVerWeb(true);
        setVerDesign(false);
        setVerImpresion(false);
    }
    const activarVerDesign = () => {
        setVerTodo(false);
        setVerWeb(false);
        setVerDesign(true);
        setVerImpresion(false);
    }
    const activarVerImpresion = () => {
        setVerTodo(false);
        setVerWeb(false);
        setVerDesign(false);
        setVerImpresion(true);
    }

    //Hooks para contacto.
    const [email, setEmail] = useState('');
    const [nombre, setNombre] = useState('');
    const [asunto, setAsunto] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [status, setStatus] = useState('');
    const [showStatus, setShowStatus] = useState(false);
    const [loader2, setLoader2] = useState(false);

    const enviarEmail = async () => {
        setLoader2(true);
        const consulta = `${nombre} (${email}), quiere hacerte una consulta sobre "${asunto}": ${mensaje}`
        try {
            const response = await axios.post('/api/send-email', 
                {
                    consulta: consulta
                }
            );
            setStatus(response.data);
            setShowStatus(true);
            setLoader2(false);
        } catch (error) {
            console.log(error)
            setStatus("error");
            setShowStatus(true);
            setLoader2(false);
        }
    }

    return (
      <div className="layout">
        {/* Background barra lateral */}
        <div className={costadoAside} onClick={ocultarMenu}></div>
        {/* Barra lateral (info) */}
        <aside className={asideActive}>
            <section className="aside__user-info">
                {/* Info general */}
                <div className="user-info__general">
                    <div className="user-info__container-imagen">
                        <img src={FotoMia} alt="Foto de perfil." className="user-info__imagen" />
                    </div>
                    <h2 className="user-info__name">Leandro Matías Pugliese</h2>
                    <h4 className="user-info__job">Desarrollador web</h4>
                </div>
                {/* Menu de navegación */}
                <nav className="layout__menu">
                    <ul className="menu__list">
                        <li className="menu__option">
                            {
                                (inicioKey) &&
                                <button className="menu__link" onClick={mostrarInicio}>
                                    <FontAwesomeIcon className="menu__icon menu__icon--active" icon={faHouse} />
                                    <span className="menu__overlay menu__overlay--active"> Inicio </span>
                                </button>
                            }
                            {
                                (!inicioKey) &&
                                <button className="menu__link" onClick={mostrarInicio}>
                                    <FontAwesomeIcon className="menu__icon" icon={faHouse} />
                                    <span className="menu__overlay"> Inicio </span>
                                </button>
                            }
                        </li>
                        <li className="menu__option">
                            
                            {
                                (sobreMiKey) &&
                                <button  className="menu__link" onClick={mostrarSobreMi}>
                                    <FontAwesomeIcon className="menu__icon menu__icon--active" icon={faUser} />
                                    <span className="menu__overlay menu__overlay--active"> Sobre mi </span>
                                </button>
                            }
                            {
                                (!sobreMiKey) &&
                                <button  className="menu__link" onClick={mostrarSobreMi}>
                                    <FontAwesomeIcon className="menu__icon" icon={faUser} />
                                    <span className="menu__overlay"> Sobre mi </span>
                                </button>
                            }
                        </li>
                        <li className="menu__option">
                            {
                                (estudiosKey) &&
                                <button  className="menu__link" onClick={mostrarEstudios}>
                                    <FontAwesomeIcon className="menu__icon menu__icon--active" icon={faGraduationCap} />
                                    <span className="menu__overlay menu__overlay--active"> Estudios </span>
                                </button>
                            }
                            {
                                (!estudiosKey) &&
                                <button  className="menu__link" onClick={mostrarEstudios}>
                                    <FontAwesomeIcon className="menu__icon" icon={faGraduationCap} />
                                    <span className="menu__overlay"> Estudios </span>
                                </button>
                            }
                        </li>
                        <li className="menu__option">
                            {
                                (portafolioKey) &&
                                <button  className="menu__link" onClick={mostrarPortafolio}>
                                    <FontAwesomeIcon className="menu__icon menu__icon--active" icon={faBriefcase} />
                                    <span className="menu__overlay menu__overlay--active"> Portafolio </span>
                                </button>
                            }
                            {
                                (!portafolioKey) &&
                                <button  className="menu__link" onClick={mostrarPortafolio}>
                                    <FontAwesomeIcon className="menu__icon" icon={faBriefcase} />
                                    <span className="menu__overlay"> Portafolio </span>
                                </button>
                            }
                        </li>
                        <li className="menu__option">
                            {
                                (skillsKey) &&
                                <button  className="menu__link" onClick={mostrarSkills}>
                                    <FontAwesomeIcon className="menu__icon menu__icon--active" icon={faComputer} />
                                    <span className="menu__overlay menu__overlay--active"> Dev skills </span>
                                </button>
                            }
                            {
                                (!skillsKey) &&
                                <button  className="menu__link" onClick={mostrarSkills}>
                                    <FontAwesomeIcon className="menu__icon" icon={faComputer} />
                                    <span className="menu__overlay"> Dev skills </span>
                                </button>
                            }
                        </li>
                        <li className="menu__option">
                            {
                                (contactoKey) &&
                                <button  className="menu__link" onClick={mostrarContacto}>
                                    <FontAwesomeIcon className="menu__icon menu__icon--active" icon={faEnvelope} />
                                    <span className="menu__overlay menu__overlay--active"> Contacto </span>
                                </button>
                            }
                            {
                                (!contactoKey) &&
                                <button  className="menu__link" onClick={mostrarContacto}>
                                    <FontAwesomeIcon className="menu__icon " icon={faEnvelope} />
                                    <span className="menu__overlay"> Contacto </span>
                                </button>
                            }
                        </li>
                    </ul>
                </nav>
                {/* links a redes sociales */}
                <div className="user-info__links">
                    <ul className="links__social">
                        <li className="social__option">
                            <a href="https://www.instagram.com/leanpugliese/?utm_medium=copy_link" className="social__link">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                        <li className="social__option">
                            <a href="https://twitter.com/LeanPugli96" className="social__link">
                                <FontAwesomeIcon icon={faSquareXTwitter}/>
                            </a>
                        </li>
                        <li className="social__option">
                            <a href="https://www.linkedin.com/in/leandro-mat%C3%ADas-pugliese-" className="social__link">
                                <FontAwesomeIcon icon={faLinkedin}/>
                            </a>
                        </li>
                        <li className="social__option">
                            <a href="https://www.youtube.com/@PugliDev" className="social__link">
                                <FontAwesomeIcon icon={faYoutube}/>
                            </a>
                        </li>
                        <li className="social__option">
                            <a href="https://github.com/Leandro-Pugliese" className="social__link">
                                <FontAwesomeIcon icon={faGithub}/>
                            </a>
                        </li>
                    </ul>
                </div>
                {/* link CV */}
                <div className="user-info__buttons">
                    <a href="/PDF/mi_cv.pdf" download="mi_cv.pdf" className="user-info__btn">
                        Curriculum Vitae
                    </a>
                </div>
                {/* footer */}
                <footer className="user-info__footer">
                    &copy; 2024 Leandro Pugliese WEB
                </footer>
            </section>
        </aside>
        {/* Menu responsive */}
        <div className="layout__menu-toggle">
            <button className="menu-toggle__button">
            {
                (menuHamburguesa) &&
                <FontAwesomeIcon className="menu-toggle__icon" icon={faBars} onClick={desplegarMenu}/>
            }
            {
                (!menuHamburguesa) &&
                <FontAwesomeIcon className="menu-toggle__icon" icon={faCircleXmark} onClick={ocultarMenu}/>
            }
            </button>                          
        </div> 
        {/* Contenido principal */}
        <main className="layout__content">
            {
                (inicioKey) && 
                <section className="content__page">
                    <h1 className="page__name"> Leandro Matías Pugliese </h1>
                    <h2 className="page__job"> Desarrollador web </h2>
                </section>
            }
            {
                (sobreMiKey) && 
                <section className="content__page content__about">
                        <header className="about__header">
                            <h1 className="about__title">
                                Sobre <span className="title__color">Mi</span>
                            </h1>
                        </header>
                        <section className="about__personal-info">
                            <article className="personal-info__bio">
                                <p className="personal-info__description">
                                    Soy amante de los autos, la aviación, el poker, los video juegos, la impresión 3D y la programación.
                                    Me encanta emprender, afrontar nuevos desafios y tener la posibilidad de aprender cosas nuevas, por eso siempre estoy en busca de
                                    nuevos emprendimientos en los cuales participar.
                                </p>
                            </article>
                            <div className="personal-info__data">
                                <ul className="peronal-info__list">
                                    <li className="personal-info__option">
                                        <span className="personal-info__title"> Edad </span>
                                        <span className="personal-info__value"> 28 </span>
                                    </li>
                                    <li className="personal-info__option">
                                        <span className="personal-info__title"> País </span>
                                        <span className="personal-info__value"> Argentina </span>
                                    </li>
                                    <li className="personal-info__option">
                                        <span className="personal-info__title"> Domicilio </span>
                                        <span className="personal-info__value"> Floresta, Ciudad de Buenos Aires </span>
                                    </li>
                                    <li className="personal-info__option">
                                        <span className="personal-info__title"> Email </span>
                                        <span className="personal-info__value"> puglieseleandro.dev@gmail.com </span>
                                    </li>
                                    <li className="personal-info__option">
                                        <span className="personal-info__title"> Teléfono </span>
                                        <span className="personal-info__value"> (+54 9) 11-3047-2114 </span>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="about__services">
                            <header className="services__header">
                                <h2 className="services__title">
                                    Lo que <span className="title__color">hago</span>
                                </h2>
                            </header>
                            <div className="services__container">

                                <article className="services__service">
                                    <div className="service__icon">
                                        <i className="fa-solid fa-code"></i>
                                    </div>
                                    <div className="servcice__content">
                                        <h3 className="service__title"> Programación </h3>
                                        <p className="service__description">
                                            Como programador estoy enfoncando mi trabajo a desarrollo web utilizando MERN Stack, el cual se basa en crear aplicaciones web completas
                                            utilizando MongoDB como base de datos, Node.js como entorno de ejecucion del lado del servidor, Express.js como framework para el servidor
                                            y React.js para crear los componentes de interfaz de usuario (el front-end).
                                        </p>
                                    </div>
                                </article>

                                <article className="services__service">
                                    <div className="service__icon">
                                        <i className="fa-solid fa-store"></i>
                                    </div>
                                    <div className="servcice__content">
                                        <h3 className="service__title"> Impresión 3D y Corte Láser </h3>
                                        <p className="service__description">
                                            Como emprendedor tengo una granja de impresión 3D y corte láser, en la cual vendemos distintos tipos de productos diseñados por nosotros
                                            y ofrecemos soluciones de impresión 3D y servicios de corte láser personalizadas para nuestros clientes.
                                        </p>
                                    </div>
                                </article>

                                <article className="services__service">
                                    <div className="service__icon">
                                        <i className="fa-brands fa-bitcoin"></i>
                                    </div>
                                    <div className="servcice__content">
                                        <h3 className="service__title"> Crypto </h3>
                                        <p className="service__description">
                                            Como amante de la programación y mi afinidad por emprender e invertir, me sumergí en el mundo crypto, donde aprendi a operar
                                            en el mercado con criptoactivos (Arbitraje y trading).
                                        </p>
                                    </div>
                                </article>
                            </div>
                        </section>
                </section>
            }
            {
                (estudiosKey) && 
                <section className="content__page content__studies">
                    <header className="studies__header">
                        <h1 className="studies__title"> Estudi<span className="subtitle__color">os</span> </h1>
                    </header>
                    <div className="studies__container">
                        <section className="studies__left">
                            <header className="studies__subheader">
                                <h3 className="studies__subtitle"> Programaci<span className="subtitle__color">ón</span> </h3>
                            </header>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Testing QA & Automatización. </h4>
                                <span className="study__description"> Codo a codo </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Master en CSS3 Avanzado: Maquetación web profesional. </h4>
                                <span className="study__description"> Udemy </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Master en Typescript, Javascript, CSS3, HTML </h4>
                                <span className="study__description"> Udemy </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Desarrollo web MongoDB - Node.js </h4>
                                <span className="study__description"> Udemy </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Desarrollo web React.js </h4>
                                <span className="study__description"> Youtube </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Desarrollo web Node.js - React.js </h4>
                                <span className="study__description"> Udemy </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-diamond"></i> Desarrollo web Python - Django - SQL </h4>
                                <span className="study__description"> Coder House </span>
                            </article>
                        </section>
                        <section className="studies__right">
                            <header className="studies__subheader">
                                <h3 className="studies__subtitle"> Otr<span className="subtitle__color">os</span> </h3>
                            </header>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-circle"></i> First Certificate in English (Ingles avanzado) </h4>
                                <span className="study__description"> Cambridge University </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-circle"></i> Piloto privado de avión (Licencia PPA) </h4>
                                <span className="study__description"> Centro de instrucción aérea 18 Cañuelas </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-circle"></i> Curso diseño e impresión 3D </h4>
                                <span className="study__description"> Domestika </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-circle"></i> Curso crypto maestro </h4>
                                <span className="study__description"> Investing.com </span>
                            </article>

                            <article className="studies__study">
                                <h4 className="study__name"><i className="fa-solid fa-circle"></i> Bachiller en ciencas naturales </h4>
                                <span className="study__description"> Instituto Saenz </span>
                            </article>
                        </section>
                    </div>
                    <div className="studies__certificates">
                        <header className="studies__subheader">
                            <h3 className="studies__subtitle"> Certifica<span className="subtitle__color">dos</span> </h3>
                        </header>
                        {/* Certificados */}
                        <section className="certificates__container">

                            <article className="certificates__certificate">
                                <div className="certificate__logo">
                                    <img src={certificadoTesting} alt="Certificado-MasterCss" className="certificate__imagen" onClick={verCertificadoTesting} />
                                    {
                                        (verTesting) &&
                                        <img src={certificadoTesting} alt="Certificado-MasterCss" className="certificate__pop-up" onClick={ocultarCertificados} />
                                    }
                                </div>
                                <div className="certificate__content">
                                    <h4 className="certificate__title"> Testing QA & Automatización </h4>
                                    <p className="certificate__temas"> (Testing manual & Automatización: Spira, Rapise, Selenium, Pruebas de estrés: Jmeter, Habilidades blandas.). </p>
                                </div>
                            </article>

                            <article className="certificates__certificate">
                                <div className="certificate__logo">
                                    <img src={certificadoMasterCss} alt="Certificado-MasterCss" className="certificate__imagen" onClick={verCertificadoMasterCss} />
                                    {
                                        (verCss) &&
                                        <img src={certificadoMasterCss} alt="Certificado-MasterCss" className="certificate__pop-up" onClick={ocultarCertificados} />
                                    }
                                </div>
                                <div className="certificate__content">
                                    <h4 className="certificate__title"> Master en CSS3 Avanzado: Maquetación de 3 sitios web profesionales </h4>
                                    <p className="certificate__temas"> (Css, html, javascript). </p>
                                </div>
                            </article>

                            <article className="certificates__certificate">
                                <div className="certificate__logo">
                                    <img src={certificadoTypescript} alt="Certificado-Typescript" className="certificate__imagen" onClick={verCertificadoTsc}/>
                                    {
                                        (verTsc) &&
                                        <img src={certificadoTypescript} alt="Certificado-Typescript" className="certificate__pop-up" onClick={ocultarCertificados}/>
                                    }
                                </div>
                                <div className="certificate__content">
                                    <h4 className="certificate__title"> Master en TypeScript, JavaScript Moderno, ES2023, APIs HTML5 </h4>
                                    <p className="certificate__temas"> (Javascript, html, css, typescript). </p>
                                </div>
                            </article>

                            <article className="certificates__certificate">
                                <div className="certificate__logo">
                                    <img src={certificadoNode} alt="Certificado-Node-React" className="certificate__imagen" onClick={verCertificadoNode} />
                                    {
                                        (verNode) &&
                                        <img src={certificadoNode} alt="Certificado-Node-React" className="certificate__pop-up" onClick={ocultarCertificados}/>
                                    }
                                </div>
                                <div className="certificate__content">
                                    <h4 className="certificate__title">  Aprende Javascript ES9, HTML, CSS3 y NodeJS desde cero </h4>
                                    <p className="certificate__temas"> (Html, css, javascript, node.js, react.js, mongoDB, git). </p>
                                </div>
                            </article>

                            <article className="certificates__certificate">
                                <div className="certificate__logo">
                                    <img src={certificadoMongo} alt="Certificado-MongoDB" className="certificate__imagen" onClick={verCertificadoMongo} />
                                    {
                                        (verMongo) &&
                                        <img src={certificadoMongo} alt="Certificado-MongoDB" className="certificate__pop-up" onClick={ocultarCertificados}/>
                                    }
                                </div>
                                <div className="certificate__content">
                                    <h4 className="certificate__title">  Curso de MongoDB - Aprende bases de datos NoSQL + API NodeJS </h4>
                                    <p className="certificate__temas">  (MongoDB, node.js, express.js). </p>
                                </div>
                            </article>

                            <article className="certificates__certificate">
                                <div className="certificate__logo">
                                    <img src={certificadoPython} alt="Certificado-Python" className="certificate__imagen" onClick={verCertificadoPython} />
                                    {
                                        (verPython) &&
                                        <img src={certificadoPython} alt="Certificado-Python" className="certificate__pop-up" onClick={ocultarCertificados}/>
                                    }
                                </div>
                                <div className="certificate__content">
                                    <h4 className="certificate__title"> Desarrollo web Python-Django </h4>
                                    <p className="certificate__temas"> (Python, Django, git, SQL). </p>
                                </div>
                            </article>
                        </section>
                    </div>
                </section>
            }
            {
                (portafolioKey) && 
                <section className="content__page content__portfolio">
                    <header className="portfolio__header">
                        <h1 className="portfolio__title"> Portafol<span className="subtitle__color">io</span> </h1>
                    </header>

                    <nav className="portfolio__navbar">
                        <ul className="portfolio__menu">
                            <li className="portfolio__option">
                                {
                                    (verTodo) &&
                                    <button className="portfolio__link portfolio__link--active" onClick={activarVerTodo}> Todo </button>
                                }
                                {
                                    (!verTodo) &&
                                    <button className="portfolio__link" onClick={activarVerTodo}> Todo </button>
                                }
                            </li>
                            <li className="portfolio__option">
                                {
                                    (verWeb) &&
                                    <button className="portfolio__link portfolio__link--active" onClick={activarVerWeb}> Web </button>

                                }
                                {
                                    (!verWeb) &&
                                    <button className="portfolio__link" onClick={activarVerWeb}> Web </button>
                                }
                            </li>
                            <li className="portfolio__option">
                                {
                                    (verDesign) &&
                                    <button className="portfolio__link portfolio__link--active" onClick={activarVerDesign}> Diseño 3D </button>
                                }
                                {
                                    (!verDesign) &&
                                    <button className="portfolio__link" onClick={activarVerDesign}> Diseño 3D </button>
                                }
                            </li>
                            <li className="portfolio__option">
                                {
                                    (verImpresion) &&
                                    <button className="portfolio__link portfolio__link--active" onClick={activarVerImpresion}> Impresión 3D </button>
                                }
                                {
                                    (!verImpresion) &&
                                    <button className="portfolio__link" onClick={activarVerImpresion}> Impresión 3D </button>
                                }
                            </li>
                        </ul>
                    </nav>

                    {
                        (verTodo) &&
                        <section className="portfolio__gallery">
                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://cambio-hoy.vercel.app/" className="gallery__link">
                                        <img src={logoCambioHoy} alt="Cambio-Hoy" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> App Cambio-Hoy </figcaption>
                                <i className="gallery__icon fa-solid fa-code"></i>
                                <span className="gallery__category"> Web </span>
                            </figure>

                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://easy-qr-generator-chi.vercel.app/" className="gallery__link">
                                        <img src={logoEasyQr} alt="Easy-QR" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> App Easy-QR </figcaption>
                                <i className="gallery__icon fa-solid fa-code"></i>
                                <span className="gallery__category"> Web </span>
                            </figure>

                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://cults3d.com/en/users/leanpugliese/3d-models" className="gallery__link">
                                        <img src={logoCults} alt="Cults3D" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> Perfil Cults 3D </figcaption>
                                <i className="gallery__icon fa-solid fa-store"></i>
                                <span className="gallery__category"> Diseño 3D </span>
                            </figure>

                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://www.instagram.com/stelea.impresion3d/?utm_source=qr&" className="gallery__link">
                                        <img src={logoStelea} alt="Stelea3D" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> Instagram Stelea 3D </figcaption>
                                <i className="gallery__icon fa-solid fa-store"></i>
                                <span className="gallery__category"> Impresión 3D </span>
                            </figure>
                        </section>
                    }
                    {
                        (verWeb) &&
                        <section className="portfolio__gallery">

                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://cambio-hoy.vercel.app/" className="gallery__link">
                                        <img src={logoCambioHoy} alt="Cambio-Hoy" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> App Cambio-Hoy </figcaption>
                                <i className="gallery__icon fa-solid fa-code"></i>
                                <span className="gallery__category"> Web </span>
                            </figure>

                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://easy-qr-generator-chi.vercel.app/" className="gallery__link">
                                        <img src={logoEasyQr} alt="Easy-QR" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> App Easy-QR </figcaption>
                                <i className="gallery__icon fa-solid fa-code"></i>
                                <span className="gallery__category"> Web </span>
                            </figure>

                        </section>
                    }
                    {
                        (verDesign) &&
                        <section className="portfolio__gallery">

                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://cults3d.com/en/users/leanpugliese/3d-models" className="gallery__link">
                                        <img src={logoCults} alt="Cults3D" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> Perfil Cults 3D </figcaption>
                                <i className="gallery__icon fa-solid fa-store"></i>
                                <span className="gallery__category"> Diseño 3D </span>
                            </figure>

                        </section>
                    }
                    {
                        (verImpresion) &&
                        <section className="portfolio__gallery">
                            
                            <figure className="gallery__item">
                                <div className="gallery__container-imagen">
                                    <a href="https://www.instagram.com/stelea.impresion3d/?utm_source=qr&" className="gallery__link">
                                        <img src={logoStelea} alt="Stelea3D" className="gallery__imagen" />
                                    </a>
                                </div>
                                <figcaption className="gallery__title"> Instagram Stelea 3D </figcaption>
                                <i className="gallery__icon fa-solid fa-store"></i>
                                <span className="gallery__category"> Impresión 3D </span>
                            </figure>

                        </section>
                    }
                </section>
            }
            {
                (skillsKey) &&
                <section className="content__page content__skills">
                    <header className="skills__header">
                        <h1 className="skills__title"> Skil<span className="subtitle__color">ls </span> </h1>
                    </header>
                    <div className="skills__container">

                        <section className="skills__left">
                            <header className="skills__subheader">
                                <h3 className="skills__subtitle"> Dev <span className="subtitle__color"> skills </span> </h3>
                            </header>
                            <div className="skills__skill-level">
                                
                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> React.js </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Node.js </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> MongoDB </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Javascript </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>
                                
                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> HTML </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> CSS </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Python </h4>
                                    <span className="skill-level__number"> 85% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage--p4"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> SQL </h4>
                                    <span className="skill-level__number"> 75% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage--p5"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Testing </h4>
                                    <span className="skill-level__number"> 90% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage--p3"></div>
                                </div>

                            </div>
                            <header className="skills__subheader">
                                <h3 className="skills__subtitle">  Diseño 3D <span className="subtitle__color"> skills </span> </h3>
                            </header>
                            <div className="skills__skill-level">

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Blender (Diseño) </h4>
                                    <span className="skill-level__number"> 75% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage--p5"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Tinkercad (Diseño) </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Fusion 360 (Diseño) </h4>
                                    <span className="skill-level__number"> 75% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage--p5"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> Ultimaker Cura (Laminado) </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                                <article className="skill-level__title">
                                    <h4 className="skill-level__text"> LightBurn (Corte láser) </h4>
                                    <span className="skill-level__number"> 100% </span>
                                </article>
                                <div className="skill-level__progressbar">
                                    <div className="skill_level__percentage skill_level__percentage"></div>
                                </div>

                            </div>
                        </section>
                        
                        <section className="skills__right">
                            <header className="skills__subheader">
                                <h3 className="skills__subtitle"> Lenguajes <span className="subtitle__color"> preferidos </span> </h3>
                            </header>
                            <div className="skills__logos-box">
                                <img className="logos-box__imagen" src={logoReact} alt="Logo react.js" />
                                <img className="logos-box__imagen" src={logoNode} alt="Logo node.js" />
                                <img className="logos-box__imagen" src={logoMongo} alt="Logo mongoDB" />
                            </div>
                            <div className="skills__logos-box logos-box__last">
                                <img className="logos-box__imagen" src={logoHtml} alt="Logo html" />
                                <img className="logos-box__imagen" src={logoJs} alt="Logo javascript" />
                                <img className="logos-box__imagen" src={logoCss} alt="Logo css" />
                            </div>
                            <header className="skills__subheader skills__subheader--other">
                                <h3 className="skills__subtitle"> Otras <span className="subtitle__color"> skills </span> </h3>
                            </header>
                            <article className="skills__other">
                                <p className="other__ability"> <span className="other__name"> Testing Software </span> (Postman, Spira, Rapise, Jmeter).</p>
                                <p className="other__ability"> <span className="other__name"> Paquete Office </span> (Excel, Word, PowerPoint).</p>
                                <p className="other__ability"> <span className="other__name"> OBS Studio </span> (Software para grabación, transmición y edición de videos).</p>
                                <p className="other__ability"> <span className="other__name"> Figma </span> (App para diseño de interfaces).</p>
                                <p className="other__ability"> <span className="other__name"> Notion </span> (Organizador de tareas multiplataforma).</p>
                                <p className="other__ability"> <span className="other__name"> Bitso Alpha </span> (Plataforma para trading de cryptoactivos).</p>
                                <p className="other__ability"> <span className="other__name"> Manejo de wallets y exchanges crypto </span> (Compra, venta, swap, etc).</p>
                            </article>
                        </section>
                    
                    </div>
                </section>
            }
            {
                (contactoKey) && 
                <section className="content__page content__contact">
                        <header className="contact__header">
                            <h1 className="contact__title"> Contac<span className="subtitle__color">to</span> </h1>
                        </header>

                        <div className="contact__container">

                            <section className="contact__info">
                                <div className="contact__data">
                                    <i className="data__icon fa-solid fa-location-dot"></i>
                                    <h2 className="data__subtitle"> Ciudad de Buenos Aires </h2>
                                </div>

                                <div className="contact__data">
                                    <a href="https://wa.me/+541130472114?text=%C2%A1Hola,%20pase%20por%20tu%20web%20y%20quer%C3%ADa%20contactarte!" className="data__link">
                                        <i className="data__icon fa-brands fa-square-whatsapp"></i>
                                        <h2 className="data__subtitle"> (+54 9) 11-3047-2114 </h2>
                                    </a>
                                </div>

                                <div className="contact__data">
                                    <a href="mailto:puglieseleandro.dev@gmail.com" className="data__link">
                                        <i className="data__icon fa-solid fa-envelope"></i>
                                        <h2 className="data__subtitle"> puglieseleandro.dev@gmail.com </h2>
                                    </a>
                                </div>

                                <div className="contact__data">
                                    <i className="data__icon fa-solid fa-circle-check"></i>
                                    <h2 className="data__subtitle"> Desarrollador web freelance </h2>
                                </div>
                            </section>

                            <section className="contact__form-box">
                                <div className="contact__map">
                                    {
                                        (loader) && 
                                        <div className="map__loader-container">
                                            <div className="map__loader"></div>
                                        </div>
                                    }
                                    {
                                        (!loader) &&
                                        <iframe className="map__box" title="location" loading="lazy" referrerPolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11043.10740819167!2d-58.481882853389834!3d-34.62601658312016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc99546769e49%3A0x4d754ad5ca377710!2sFloresta%2C%20CABA!5e0!3m2!1ses!2sar!4v1697053676189!5m2!1ses!2sar"/>
                                    }
                                </div>

                                <header className="contact__form-header">
                                    <h3 className="contact__subtitle"> ¿Comó puedo <span className="subtitle__color"> ayudarte? </span> </h3>
                                </header>
                                <div className="contact__form">
                                    <div className="form__container">
                                        <section className="form__left">
                                            <div className="form__group">
                                                <input className="form__input" type="text" name="name" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre" required />
                                                <label className="form__label"> Nombre </label>
                                            </div>
                                            <div className="form__group">
                                                <input className="form__input" type="email" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                                                <label className="form__label"> Email </label>
                                            </div>
                                            <div className="form__group">
                                                <input className="form__input" type="text" name="subject" onChange={(e) => setAsunto(e.target.value)} placeholder="Asunto" required />
                                                <label className="form__label"> Asunto </label>
                                            </div>
                                        </section>
                                        <section className="form__right">
                                            <div className="form__group">
                                                <textarea className="form__input form__input--textarea" name="message" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje" required />
                                                <label className="form__label"> Mensaje </label>
                                            </div>
                                        </section>
                                    </div>
                                    {
                                        (loader2) && 
                                        <div className="map__loader-container">
                                            <div className="map__loader"></div>
                                        </div>
                                    }
                                    {
                                        (!loader2) &&
                                        <div className="form__button-container">
                                            <button className="form__button" onClick={enviarEmail}>Enviar mensaje</button>
                                        </div>
                                    }
                                    {
                                        (showStatus && !loader2) &&
                                        <div className="mensaje__envio">{status}</div>
                                    }
                                </div>
                            </section>
                        </div>
                </section>
            }
        </main>
      </div>
    );
}
  
export default Inicio;
  